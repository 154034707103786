<template>
	<div>
		<div class="container-fluid my-4">
			<b-tabs v-model="tabIndex" @input="tabChanged">
				<!-- ---------------------------------------------------------------------------------------- -->
				<!-- pages -->
				<!-- ---------------------------------------------------------------------------------------- -->

				<b-tab title="Pagina beheer">
					<button class="btn btn-outline-secondary my-3" @click="createPage()">
						<v-icon name="plus-square" scale="2" class="align-middle mr-2" />pagina toevoegen
					</button>
					<table class="pagesTable">
						<thead class="border-bottom">
							<th class="w-25">pagina</th>
							<th class="w-auto">pad / url</th>
							<th class="w-25">titel</th>
							<th>menu-item</th>
							<th>
								<a v-b-tooltip.v-danger title="Nivo naar links">
									<v-icon name="caret-left" :scale="iconScale" />
								</a>
								<a v-b-tooltip.v-danger title="Nivo">#</a>
								<a v-b-tooltip.v-danger title="Nivo naar rechts">
									<v-icon name="caret-right" :scale="iconScale" />
								</a>
								<a v-b-tooltip.v-danger title="Naar boven verplaatsen">
									<v-icon name="caret-up" :scale="iconScale" />
								</a>
								<a v-b-tooltip.v-danger title="Volgorde">#</a>
								<a v-b-tooltip.v-danger title="Naar beneden verplaatsen">
									<v-icon name="caret-down" :scale="iconScale" />
								</a>
							</th>
							<th>
								<a v-b-tooltip.v-danger title="Aanpassen pagina">
									<v-icon name="edit" />
								</a>
								<a v-b-tooltip.v-danger title="Pagina verwijderen">
									<v-icon name="trash-alt" />
								</a>
							</th>
						</thead>

						<tr>
							<td>Hoofdpagina</td>
							<td></td>
							<td></td>
							<td class="text-center">
								<input
									type="checkbox"
									v-if="pages.length"
									v-model="pages[0].menu_item"
									@change="updatePage(pages[0], 'menu_item', pages[0].menu_item)"
								/>
							</td>
							<td></td>
							<td>
								<a @click="editPage(pages[0])">
									<v-icon name="edit" />
								</a>
							</td>
						</tr>
						<tr v-for="p in isNotHome" :key="p.id" class="border-top border-bottom">
							<td class="pr-4" :class="{ 'pl-0': p.level == 1, 'pl-4': p.level == 2, 'pl-5': p.level >= 3 }">
								<div>{{ p.title }}</div>
							</td>
							<td class="pr-4">
								<editable :item="p.path" @updated="updatePage(p, 'path', $event)"></editable>
							</td>
							<td class="pr-4">
								<editable :item="p.title" @updated="updatePage(p, 'title', $event)"></editable>
							</td>
							<td class="text-center">
								<input
									type="checkbox"
									v-model="p.menu_item"
									@change="updatePage(p, 'menu_item', p.menu_item)"
								/>
							</td>
							<td class="pr-4">
								<a @click="left(p)">
									<v-icon name="caret-left" :scale="iconScale" />
								</a>
                                {{ p.level }}
								<a @click="right(p)">
									<v-icon name="caret-right" :scale="iconScale" />
								</a>
								<a @click="up(p)">
									<v-icon name="caret-up" :scale="iconScale" />
								</a>
								{{ p.rank }}
								<a @click="down(p)">
									<v-icon name="caret-down" :scale="iconScale" />
								</a>
							</td>
							<td>
								<a @click="editPage(p)">
									<v-icon name="edit" />
								</a>
								<a @click="confirmDelete(p)">
									<v-icon name="trash-alt" />
								</a>
							</td>
						</tr>
					</table>
				</b-tab>

				<!-- ---------------------------------------------------------------------------------------- -->
				<!-- page content -->
				<!-- ---------------------------------------------------------------------------------------- -->

				<b-tab title="Pagina inhoud">
					<div v-show="!isEditing">
						<!-- page selector -->
						<div class="row mt-4">
							<div class="col-md-1">pagina:</div>
							<div class="col-md-3">{{ this.selected == '' ? 'niet geselecteerd' : this.selected }}</div>
							<div class="col-md-3" v-if="selected">
								<button
									class="btn btn-outline-secondary btn-sm"
									v-bind:disabled="selected == ''"
									@click="createRow(selected)"
								>
									<v-icon name="plus-square" scale="2" class="align-middle mr-2" />regel toevoegen
								</button>
							</div>
							<br />
							<br />
						</div>

						<!-- header -->
						<!-- <div class="row mt-4 py-3 border rounded shadow" v-if="selected">
							<div class="col-1">header:</div>
							<div class="col-3">
								<editable
									class="border"
									:item="selectedPage.header_title"
									@updated="updatePage(selectedPage, 'header_title', $event)"
								></editable>
							</div>
							<div class="col">
								<editable
									class="border"
									:item="selectedPage.header_quote"
									@updated="updatePage(selectedPage, 'header_quote', $event)"
								></editable>
							</div>
							<div class="col">
								<editable
									class="border"
									:item="selectedPage.header_image"
									@updated="updatePage(selectedPage, 'header_image', $event)"
								></editable>
							</div>
						</div> -->

						<!-- rows -->
						<div v-if="quarks.length != undefined">
							<div class="row mt-4 border rounded shadow" v-for="(q, index) in quarks" :key="index">
								<!-- row properties -->
								<div class="col-4">
									<table class="table table-borderless table-sm my-3">
										<!-- title -->
										<tr>
											<td>rij titel:</td>
											<td class="border">
												<editable
													:item="q.title"
													@updated="updateRow(q, 'title', $event)"
												></editable>
											</td>
										</tr>

										<!-- rank -->
										<tr>
											<td>volgorde:</td>
											<td>
												<a
													@click="upRow(q)"
													v-b-tooltip.v-danger
													title="Naar boven verplaatsen"
												>
													<v-icon name="caret-up" :scale="iconScale" />
												</a>
												{{ q.rank }}
												<a
													@click="downRow(q)"
													v-b-tooltip.v-danger
													title="Naar beneden verplaatsen"
												>
													<v-icon name="caret-down" :scale="iconScale" />
												</a>
											</td>
										</tr>

										<!-- nr of columns -->
										<tr>
											<td>kolommen:</td>
											<td>
												<a @click="addToRowNrCols(q, -1)">
													<v-icon name="caret-left" :scale="iconScale" />
												</a>
												{{ q.nrCols }}
												<a @click="addToRowNrCols(q, 1)">
													<v-icon name="caret-right" :scale="iconScale" />
												</a>
												<editable
													:item="q.cols"
													@updated="updateRow(q, 'cols', $event)"
												></editable>
											</td>
										</tr>

										<!-- checkbox row style -->
										<tr>
											<td>speciale rij stijl</td>
											<td>
												<input
													type="checkbox"
													v-model="q.configs[0]"
													true-value="1"
													false-value="0"
													@change="checkboxToggle(q)"
												/>
											</td>
										</tr>

										<!-- checkbox column style -->
										<tr>
											<td>speciale kolom stijl</td>
											<td>
												<input
													v-for="n in q.nrCols"
													:key="n"
													type="checkbox"
													class="mr-2"
													v-model="q.configs[n]"
													true-value="1"
													false-value="0"
													@change="checkboxToggle(q)"
												/>
											</td>
										</tr>

										<!-- delete row icon -->
										<tr>
											<td></td>
											<td>
												<a @click="deleteRow(index, q.id)">
													<v-icon name="trash-alt" scale="1.2" />
												</a>
											</td>
										</tr>
									</table>
								</div>

								<!-- row content columns -->
								<div class="col">
									<table class="table table-bordered my-3" v-show="!isEditing">
										<tbody>
											<tr>
												<td>
													<div class="container">
														<div class="row">
															<div
																class="col border"
																v-for="n in q.nrCols"
																:key="n"
																@click="editQuark(n - 1, index)"
															>
																<div v-html="q.columns[n - 1]"></div>
															</div>
														</div>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>

					<!-- visible when editing -->
					<div v-show="isEditing">
						<!-- save close buttons -->
						<div class="row my-2">
							<div class="col-md-2">
								<button type="button" class="btn btn-outline-secondary" @click="closeEditor(true)">
									<v-icon name="save" scale="2" class="align-middle mr-3" />save
								</button>
							</div>
							<div class="col-md-2">
								<button type="button" class="btn btn-outline-secondary" @click="closeEditor(false)">
									<v-icon name="times" scale="2" class="align-middle mr-3" />cancel
								</button>
							</div>
						</div>

						<!-- editor window -->
						<div class="row">
							<div class="col-md-12">
								<vue-ckeditor v-model="editorData" :config="editorConfig" @blur="onBlur"></vue-ckeditor>
							</div>
						</div>
					</div>
				</b-tab>

				<b-tab title="File upload">
					<div class="row mt-4">
						<div class="col-10 offset-1 text-center border py-4">
							<h3>File upload</h3>
							<vue-dropzone
								ref="myVueDropzone"
								id="dropzone"
								:options="dropzoneOptions"
								:useCustomSlot="true"
							>
								<div class="dropzone-custom-content">
									<v-icon name="upload" scale="3" />
									<div>Sleep bestand hier naartoe</div>
									<div>...of klik om een bestand te selecteren</div>
								</div>
							</vue-dropzone>
						</div>
					</div>
					<div class="row mt-4">
						<div class="col-2 my-1" v-for="upl in uploads" :key="upl">
							<div class="card h-100">
								<img :src="upl" class="card-img-top" />
								{{ upl.replace('../uploads/', '') }}
								<a class="mt-auto" @click="deleteFile(upl)">
									<v-icon name="trash-alt" />
								</a>
							</div>
						</div>
					</div>
				</b-tab>
			</b-tabs>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';

import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
Vue.use(VuejsDialog);

import { TabsPlugin } from 'bootstrap-vue';
Vue.use(TabsPlugin);

import { TooltipPlugin } from 'bootstrap-vue';
Vue.use(TooltipPlugin);

// import axios from '@/js/axios';
import axios from 'axios';
import utils from '@/js/utils';
import _ from 'lodash';

import editable from '@/components/Editable';
import VueCkeditor from 'vue-ckeditor2';

// fontawesome icons
import 'vue-awesome/icons/caret-up';
import 'vue-awesome/icons/caret-right';
import 'vue-awesome/icons/caret-down';
import 'vue-awesome/icons/caret-left';
import 'vue-awesome/icons/edit';
import 'vue-awesome/icons/plus';
import 'vue-awesome/icons/minus';
import 'vue-awesome/icons/plus-square';
import 'vue-awesome/icons/save';
import 'vue-awesome/icons/search-plus';
import 'vue-awesome/icons/times';
import 'vue-awesome/icons/trash-alt';
import 'vue-awesome/icons/upload';
import Icon from 'vue-awesome/components/Icon';

// file uploader
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
	data: () => ({
		// BASE_URL: 'http://localhost:2222/php/cms.php',
		BASE_URL: '/php/cms.php',
		TABLE_PAGES: '/pages/',
		TABLE_ROWS: '/rows/',

		UPLOADS_URL: '/php/get-uploads.php',
		uploads: [],

		isActive: true,
		isEditing: false,
		editColumn: 0,
		editRowIndex: -1,
		selected: '',
		selectedPage: {},

		pages: [],
		quarks: [],

		editorData: '',
		editorConfig: {
			// toolbar: 'Standard',
			// toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'Sourcedialog']],
			// extraPlugins: 'sourcedialog',
			height: 300,
		},

		tabIndex: 0,
		iconScale: 1.8,

		// file upload tab
		dropzoneOptions: {
			addRemoveLinks: true,
			url: '/php/file-upload.php',
			maxFilesize: 6,
			// thumbnailWidth: 150,
			// headers: { 'My-Awesome-Header': 'header value' }
		},
	}),

	computed: {
		isNotHome(index) {
			return this.pages.filter((page) => page.path != 'home');
		},
	},

	components: {
		VueCkeditor,
		editable,
		'v-icon': Icon,
		vueDropzone,
	},

	// watch: {
	// 	tabIndex(val) {
	// 		localStorage.setItem('last-tab', this.tabIndex);
	// 	}
	// },

	created() {
		console.clear();
		this.getPages();

		// this.tabIndex = parseInt(localStorage.getItem('last-tab'));
	},

	methods: {
		// ----------------------------------------------------------------------------------------
		// editor
		// ----------------------------------------------------------------------------------------

		// edit quark row
		editQuark(column, index) {
			console.log('editQuark', column, index);
			this.editColumn = column;
			this.editRowIndex = index;
			this.editorData = this.quarks[index].columns[column];
			this.isEditing = true;
		},

		onBlur() {
			console.log('onBlur'); //, this.editorData);

			// fix reactivity of 2d array
			// this.quarks[this.editRowIndex].columns[this.editColumn] = this.editorData;

			// make copy of row
			let copy = this.quarks[this.editRowIndex];

			//update the value
			copy.columns[this.editColumn] = this.editorData;

			// update it in the array
			Vue.set(this.quarks, this.editRowIndex, copy);

			// update in database
			let row = this.quarks[this.editRowIndex];
			row.col1 = row.columns[0];
			row.col2 = row.columns[1];
			row.col3 = row.columns[2];
			row.col4 = row.columns[3];
			this.updateRow(row);
		},

		onFocus(editor) {
			console.log('onFocus');
			// console.log(editor)
		},

		closeEditor(e) {
			console.log('closeeditor', e);
			this.isEditing = false;
		},

		// ----------------------------------------------------------------------------------------
		// tabs events
		// ----------------------------------------------------------------------------------------

		tabChanged(selectedTab) {
			if (selectedTab == 2) {
				this.getUploads();
			}
		},

		// ----------------------------------------------------------------------------------------
		// uploads
		// ----------------------------------------------------------------------------------------

		getUploads() {
			axios
				.get(this.UPLOADS_URL)
				.then((response) => {
					this.uploads = response.data;
					// console.log(this.uploads);
				})
				.catch((error) => {
					console.log(error);
				});
		},

		deleteFile(file) {
			let url = '/php/delete-file.php?delete=' + file;
			axios
				.get(url)
				.then((response) => {
					console.log(response.data);
					this.getUploads();
				})
				.catch((error) => {
					console.log(error);
				});
		},

		// ----------------------------------------------------------------------------------------
		// pages
		// ----------------------------------------------------------------------------------------

		// create page in database
		async createPage() {
			console.log('createPage');

			var newPage = {
				path: '<wijzigen>',
				title: '<wijzigen>',
				rank: this.pages.length,
			};

			let url = this.BASE_URL + this.TABLE_PAGES;
			await axios
				.post(url, newPage)
				.then((response) => {
					console.log(response);
					var result = response.data.success;
					if (result && result.code == 201) {
						console.log(result.code, result.status);
					}
					this.getPages();
				})
				.catch((error) => {
					console.log(error);
				});
		},

		// read pages from database
		async getPages(rank) {
			console.log('getPages');

			this.pages = [];
			let url = this.BASE_URL + this.TABLE_PAGES;
			console.log(url);
			const result = await axios.get(url);
			this.pages = result.data;
			utils.sortByRank(this.pages);
			console.log('getpages', this.pages);

			if (rank) {
				this.rankPages();
			}
		},

		// update page into database
		async updatePage(page, col, newValue) {
			let url = this.BASE_URL + this.TABLE_PAGES + page.id;
			console.log('updatePage', url);

			if (col != undefined) {
				console.log(page, col, newValue);
				page[col] = newValue;
				console.log(page, col, newValue);
			}

			const result = await axios.put(url, page);
			console.log('update', result.data);
		},

		// delete page from database
		async deletePage(page) {
			console.log('deletePage', page);

			let url = this.BASE_URL + this.TABLE_PAGES + page.id;
			await axios
				.delete(url)
				.then((response) => {
					if (response.data.success) {
						console.log('deleted');
						this.getPages(true);
					} else {
						console.log('Error', response.data.error.code, ':', response.data.error.status);
						// this.$toast.open('Regel verwijderd')
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},

		confirmDelete(page) {
			this.$dialog
				.confirm('Deze pagina verwijderen?', {
					okText: 'OK',
					cancelText: 'Cancel',
				})
				.then((dialog) => {
					this.deletePage(page);
				})
				.catch((dialog) => {
					console.log('Clicked on cancel');
				});
		},

		editPage(page) {
			console.log('page', page);
			this.selected = page.path == '' ? 'home' : page.path;
			this.selectedPage = page;
			this.getRows(page.path);
			this.tabIndex = 1;
		},

		async rankPages() {
			console.log('rankPages');
			for (let i = 0; i < this.pages.length; i++) {
				let p = this.pages[i];
				p.rank = i.toString();
				await this.updatePage(p);
			}
		},

		async addToPageRank(page, offset) {
			// add offset to string
			let next = (parseInt(page.rank) + offset).toString();
			let nextPage = _.find(this.pages, ['rank', next]);
			console.log(page.path, '<=>', nextPage.path);

			let current = page.rank;
			page.rank = next;
			nextPage.rank = current;

			utils.sortByRank(this.pages);
			await this.updatePage(page);
			await this.updatePage(nextPage);
		},
		left(page) {
			if (page.level > 1) {
				page.level--;
				this.updatePage(page);
			}
		},
		right(page) {
			if (page.level <= 3) {
				page.level++;
				this.updatePage(page);
			}
		},
		up(page) {
			if (page.rank > 1) {
				this.addToPageRank(page, -1);
			}
		},
		down(page) {
			if (page.rank < this.pages.length) {
				this.addToPageRank(page, 1);
			}
		},

		// ----------------------------------------------------------------------------------------
		// content rows
		// ----------------------------------------------------------------------------------------

		// get id from given page path
		getIdFromPath(path) {
			let id = -1;
			for (let i = 0; i < this.pages.length; i++) {
				let pagePath = this.pages[i].path;
				// if (pagePath == 'home') pagePath = '';
				if (path == pagePath) {
					id = this.pages[i].id;
					break;
				}
			}

			return id;
		},

		// add new row to database
		async createRow(selected) {
			console.clear();
			console.log('create row', selected);

			let id = this.getIdFromPath(selected);
			let url = this.BASE_URL + this.TABLE_ROWS;
			// console.log(url);

			var newRow = {
				page_id: id,
				rank: this.quarks.length,
			};

			await axios
				.post(url, newRow)
				.then((response) => {
					console.log(response);
					var result = response.data.success;
					if (result && result.code == 201) {
						// console.log(result.code, result.status);
						this.getRows(selected);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},

		// get rows from database
		async getRows(selectedPath) {
			console.log('getRows', selectedPath);

			this.quarks = [];

			let id = this.getIdFromPath(selectedPath);
			let url = this.BASE_URL + this.TABLE_ROWS + 'page_id/' + id;

			const result = await axios.get(url);
			// .then(response => {
			//     return response.data;
			// 	// console.log(response.data);
			// })
			// .catch(e => {
			// 	// this.errors.push(e)
			// 	//console.log(e);
			// })
			if (!result.error) {
				this.quarks = result.data;
				if (this.quarks.length > 0) {
					for (let i = 0; i < this.quarks.length; i++) {
						let q = this.quarks[i];

						// if (q.col1.indexOf('img') != -1) {
						// 	q.col1 = utils.img_fluid(q.col1);
						// }

						// generate new property - nrCols
						let arr = q.cols.split(';');
						q.nrCols = arr.length;

						// generate new property - columns
						q.columns = [];
						q.columns.push(q.col1);
						q.columns.push(q.col2);
						q.columns.push(q.col3);
						q.columns.push(q.col4);

						// generate new property - column configuration
						q.configs = [0,0,0,0,0,0,0,0]; 
						for (let i = 0; i < q.configs.length; i++) {
							let mask = 1 << i;
							q.configs[i] = (q.config & mask) == mask ? 1 : 0;
						}
					}
					console.log('getRows', this.quarks);

					utils.sortByRank(this.quarks);
					await this.rankRows(this.quarks);
				}
			} else {
				console.log('Error', response.data.error.code, ':', response.data.error.status);
				// this.$toast.open('Regel verwijderd')
			}
		},

		// update row in database
		async updateRow(row, col, newVal) {
			console.log('updateRow');

			// var config = {
			// 	headers: {
			// 		'X-HTTP-Method-Override': 'PUT'
			// 	}
			// };
			// .put(url, row, config)

			var url = this.BASE_URL + this.TABLE_ROWS + row.id;

			if (col != undefined && newVal != undefined) {
				row[col] = newVal;
			}

			// delete added rows via copy
			const copiedRow = JSON.parse(JSON.stringify(row));
			delete copiedRow.nrCols;
			delete copiedRow.columns;
			delete copiedRow.configs;

			// update in database
			await axios
				.put(url, copiedRow)
				.then((response) => {
					console.log('updateRow', response.data);
					//this.getRows(this.selected);
				})
				.catch((error) => {
					console.log(error);
				});
		},

		// delete row from database
		async deleteRow(index, id) {
			// delete row from database
			let url = this.BASE_URL + this.TABLE_ROWS + id;
			console.log(url);

			await axios
				.delete(url)
				.then((response) => {
					if (response.data.success) {
						this.getRows(this.selected);
					} else {
						console.log('Error', response.data.error.code, ':', response.data.error.status);
						// this.$toast.open('Regel verwijderd')
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},

		async rankRows() {
			console.log('rankRows');
			for (let i = 0; i < this.quarks.length; i++) {
				let q = this.quarks[i];
				q.rank = i.toString();
				await this.updateRow(q);
			}
		},

		async addToRowRank(row, offset) {
			// add offset to string
			let next = (parseInt(row.rank) + offset).toString();
			let nextRow = _.find(this.quarks, ['rank', next]);

			let current = row.rank;
			row.rank = next;
			nextRow.rank = current;

			utils.sortByRank(this.quarks);
			await this.updateRow(row);
			await this.updateRow(nextRow);
		},
		upRow(row) {
			if (row.rank > 0) {
				this.addToRowRank(row, -1);
			}
		},
		downRow(row) {
			if (row.rank < this.quarks.length - 1) {
				this.addToRowRank(row, 1);
			}
		},
		addToRowNrCols(row, offset) {
			if ((offset < 0 && row.nrCols > 1) || (offset > 0 && row.nrCols < 4)) {
				row.nrCols += offset;
				let colW = (12 / row.nrCols).toString();
				row.cols = colW;
				for (let i = 0; i < row.nrCols - 1; i++) row.cols += ';' + colW;
				this.updateRow(row);
			}
		},
		checkboxToggle(row) {
			let r = 0;
			for (let i = 0; i < row.configs.length; i++) {
				console.log('configs', row.configs[i]);
				r += row.configs[i] << i;
			}
			console.log('config', r);
			row.config = r;
			this.updateRow(row);
		},
	},
};
</script>

<style>
@import '~bootstrap-vue/dist/bootstrap-vue.min.css';

.container-fluid {
    color: #2c3e50;
    background-color: white;
}

/* tr:nth-child(even) {
	background-color: #f2f2f2;
} */

.pagesTable tr:hover {
	background-color: #ffffe6;
}

.fa-icon {
	margin: 0 8px;
	cursor: pointer;
}

.tdp {
	padding: 0 10px;
}

.dg-btn {
	border-width: 1px;
	font-weight: normal;
}

.img-icon {
	height: 10px;
}
</style>
